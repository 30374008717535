:root {
  --pbpadding: 3em;
}

:root {
  --base-font-size: 20px;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  color: #000000;
  font-size: var(--base-font-size);
  font-family: "poppins-reg", sans-serif, Arial;
  font-variation-settings: "wght" 400;
  background-color: white;
  position: relative;
}

p {
  line-height: 1.75;
  margin-bottom: 1.25em;
}

strong,
b {
  font-variation-settings: "wght" 600;
}

img {
  height: auto;
}

h2 + p,
h3 + p,
h4 + p,
h5 + p,
h5 + ul {
  margin-top: 1rem;
}

p + ul {
  margin-bottom: 2em;
}

a:not(.nav-link, .skiplink, .reset-link), .link {
  color: #000;
  text-decoration: none;
}
a:not(.nav-link, .skiplink, .reset-link):hover, .link:hover {
  text-decoration: underline;
}

article.text-side a, article a {
  text-underline-offset: 0.3em;
}

.reset-link {
  text-decoration: none !important;
  color: black !important;
}
.reset-link:hover {
  text-decoration: underline !important;
  text-underline-offset: 3px;
}

:is(.backto.backto) {
  text-decoration: none;
  opacity: 0.6;
  margin-top: 2rem;
  display: inline-block;
}
:is(.backto.backto) img {
  width: 27px;
  margin-right: 5px;
}
:is(.backto.backto):hover {
  text-decoration: underline;
}

.mysuperflexteststyle {
  background-color: red;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

*:focus:not(:focus-visible) {
  outline: none !important;
}

div.objectfit {
  height: 100%;
}
div.objectfit img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

svg.icon {
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  fill: currentColor;
  position: relative;
  top: 3px;
  margin: -2px 5px 0 0;
}

.skiplink {
  position: absolute;
  left: -9999em;
}
.skiplink:focus {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  font-size: 2rem;
  z-index: 1000;
  background: #000000;
  padding: 1rem 1.8rem;
  border: 4px solid white;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.centred {
  text-align: center;
}

.align-right {
  text-align: right;
}

.gorel {
  position: relative;
}

.rounded-box {
  border-radius: 69px;
  overflow: hidden;
}
.rounded-box__square-bottom-left {
  border-radius: 69px 69px 69px 0;
}

.bg-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-blue {
  background-color: #452775;
  color: #FFF;
}

.bg-grey {
  background-color: #666;
}

.bg-greylt {
  background-color: #E5E5E5;
}

.bg-sky {
  background-color: #5DABBF;
}

.gowhite {
  color: #FFF;
}

.goblue {
  color: #452775;
}

.bordered-blue {
  border: 0.5px solid #452775;
}

.nopadding-top {
  padding-top: 0 !important;
}

.nopadding-bottom {
  padding-bottom: 0 !important;
}

.pb-copy-panel .copy-wrapper.align-top .type-copy {
  padding-top: 0;
  padding-bottom: 75px;
}
.pb-copy-panel .copy-wrapper.align-top-padded .type-copy {
  padding-top: 75px;
  padding-bottom: 75px;
}
.pb-copy-panel .copy-wrapper.align-middle {
  align-items: center;
}
.pb-copy-panel .copy-wrapper.align-bottom {
  align-items: flex-end;
}
.pb-copy-panel .copy-wrapper:before, .pb-copy-panel .copy-wrapper:after {
  display: none;
}
.pb-copy-panel .copy-wrapper .leftcol.copycol h3, .pb-copy-panel .copy-wrapper .leftcol.copycol h4, .pb-copy-panel .copy-wrapper .rightcol.copycol h3, .pb-copy-panel .copy-wrapper .rightcol.copycol h4 {
  margin-bottom: 5.5rem;
  font-family: "poppins-medium", sans-serif;
}
.pb-copy-panel .copy-wrapper .leftcol.copycol h3, .pb-copy-panel .copy-wrapper .rightcol.copycol h3 {
  font-size: clamp(30px, 4vw, 50px);
}
.pb-copy-panel .copy-wrapper .leftcol.copycol h4, .pb-copy-panel .copy-wrapper .rightcol.copycol h4 {
  font-size: 30px;
}
.pb-copy-panel .copy-wrapper .leftcol.copycol .btn, .pb-copy-panel .copy-wrapper .rightcol.copycol .btn {
  margin-top: 2em;
}
.pb-copy-panel .copy-wrapper .leftcol img, .pb-copy-panel .copy-wrapper .rightcol img {
  margin-top: 0;
  border-radius: 75px;
}
.pb-copy-panel .copy-wrapper .leftcol img.not-rounded, .pb-copy-panel .copy-wrapper .rightcol img.not-rounded {
  border-radius: 0;
}
.pb-copy-panel .copy-wrapper .leftcol.type-image img {
  float: left;
}
.pb-copy-panel .copy-wrapper .rightcol.type-image img {
  float: right;
}
.pb-copy-panel .copy-wrapper .nexbtn {
  margin-top: 1.8em;
}
@media (max-width: 768px) {
  .pb-copy-panel .copy-wrapper .leftcol.copycol h3.key-heading {
    margin-left: auto;
    margin-right: auto;
  }
}
.pb-copy-panel.copy-alt .copy-wrapper.align-top .type-copy {
  padding-top: 0;
  padding-bottom: 0;
}
.pb-copy-panel.shift-up {
  margin-top: -75px;
}
.pb-copy-panel__twocol-fifty .copy-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 1em;
}
.pb-copy-panel__twocol-fifty .copy-wrapper .leftcol, .pb-copy-panel__twocol-fifty .copy-wrapper .rightcol {
  flex-basis: 50%;
}
@media (max-width: 1480px) {
  .pb-copy-panel .copy-wrapper.align-top .type-copy {
    padding-top: 0;
    padding-bottom: 0;
  }
  .pb-copy-panel .copy-wrapper.align-bottom {
    align-items: flex-start;
  }
  .pb-copy-panel.shift-up {
    margin-top: var(--pbpadding);
  }
  .pb-copy-panel__twocol-fifty .copy-wrapper {
    gap: 2.5em;
  }
}
@media (max-width: 768px) {
  .pb-copy-panel .copy-wrapper .leftcol.type-copy .btn, .pb-copy-panel .copy-wrapper .rightcol.type-copy .btn {
    margin-bottom: 1.25em;
  }
  .pb-copy-panel .copy-wrapper .leftcol.type-image img, .pb-copy-panel .copy-wrapper .rightcol.type-image img {
    float: none;
  }
  .pb-copy-panel .copy-wrapper .leftcol.no-margin {
    margin-bottom: 0;
  }
  .pb-copy-panel.shift-up.nopadding-top {
    padding-top: var(--pbpadding) !important;
  }
  .pb-copy-panel.mobile-reverse .copy-wrapper {
    flex-direction: column-reverse;
  }
  .pb-copy-panel__twocol-fifty .copy-wrapper {
    gap: var(--pbpadding);
    flex-direction: column;
  }
  .pb-copy-panel__twocol-fifty .copy-wrapper .leftcol, .pb-copy-panel__twocol-fifty .copy-wrapper .rightcol {
    width: 100%;
    text-align: center;
  }
}