@import '../abstracts/variables.scss';
@import '../abstracts/mixins.scss';
@import '../sections/base.scss';

.pb-copy-panel {
    .copy-wrapper {
        &.align-top {
            .type-copy {
                padding-top: 0;
                padding-bottom: 75px;
            }
        }
        &.align-top-padded {
            .type-copy {
                padding-top: 75px;
                padding-bottom: 75px;
            }
        }
        &.align-middle {
            align-items: center;
        }
        &.align-bottom {
            align-items: flex-end;
        }
        &:before, &:after {
            display: none;
        }
        .leftcol, .rightcol {
            &.copycol {
                h3, h4 {
                    margin-bottom: 5.5rem;
                    font-family: $font-med;
                }
                h3 {
                    font-size: clamp(30px, 4vw, 50px);
                }
                h4 {
                    font-size: 30px;
                }
                .btn {
                    margin-top: 2em;
                }
            }
            img {
                margin-top: 0;
                border-radius: 75px;
                &.not-rounded {
                    border-radius: 0;
                }
            }
        }
        .leftcol {
            &.type-image {
                img {
                    float: left;
                }
            }
        }
        .rightcol {
            &.type-image {
                img {
                    float: right;
                }
            }
        }
        .nexbtn {
            margin-top: 1.8em;
        }
        @include bpr-media-breakpoint(down, md) {
            .leftcol {
                &.copycol {
                    h3 {
                        &.key-heading {
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                }
            }
        }
    }
    &.copy-alt {
        .copy-wrapper {
            &.align-top {
                .type-copy {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }
    }
    &.shift-up {
        margin-top: -75px;
        //transform: translateY(-75px);
    }
    &__twocol-fifty {
        .copy-wrapper {
            display: flex;
            justify-content: space-between;
            gap: 1em;
            .leftcol, .rightcol {
                flex-basis: 50%;
            }
        }
    }
    @include bpr-media-breakpoint(down, xxl) {
        .copy-wrapper {
            &.align-top {
                .type-copy {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
            &.align-bottom {
                align-items: flex-start;
            }
        }
        &.shift-up {
            margin-top: var(--pbpadding);
        }
        &__twocol-fifty {
            .copy-wrapper {
                gap: 2.5em;
            }
        }
	}
    @include bpr-media-breakpoint(down, md) {
        .copy-wrapper {
            .leftcol, .rightcol {
                &.type-copy {
                    .btn {
                        margin-bottom: 1.25em;
                    }
                }
                &.type-image {
                    img {
                        float: none;
                    }
                }
            }
            .leftcol {
                &.no-margin {
                    margin-bottom: 0;
                }
            }
        }
        &.shift-up {
            &.nopadding-top {
                padding-top: var(--pbpadding) !important;
            }
        }
        &.mobile-reverse {
            .copy-wrapper {
                flex-direction: column-reverse;
            }
        }
        &__twocol-fifty {
            .copy-wrapper {
                gap: var(--pbpadding);
                flex-direction: column;
                .leftcol, .rightcol {
                    width: 100%;
                    text-align: center;
                }
            }
        }
	}
}