@use 'sass:math';

@mixin bpr-media-breakpoint($upOrDown, $breakpointSize) {
    $size: null;
    @if $breakpointSize == 'sm' {
        $size: 480px;
    }
    @if $breakpointSize == 'md' {
        $size: 768px;
    }
    @if $breakpointSize == 'lg' {
        $size: 980px;
    }
    @if $breakpointSize == 'hd' {
        $size: 1080px;
    }
    @if $breakpointSize == 'xl' {
        $size: 1200px;
    }
    @if $breakpointSize == 'xxl' {
        $size: 1480px;
    }

    @if $upOrDown == 'up' {
        @media (min-width: $size) {
            @content;
        }
    }
    @if $upOrDown == 'down' {
        @media (max-width: $size) {
            @content;
        }
    }
}

// RESPONSIVE FONTS @include fluid-type(1.3rem, 1.7rem);
@mixin fluid-type($min-font-size: 1.3rem, $max-font-size: 2.2rem, $lower-range: 540px, $upper-range: 960px) {
  font-size: calc(#{$min-font-size} + #{((math.div($max-font-size, $max-font-size * 0 + 1)) - (math.div($min-font-size, $min-font-size * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{((math.div($upper-range, $upper-range * 0 + 1)) - (math.div($lower-range, $lower-range * 0 + 1)))}));
  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range){
    font-size: $max-font-size;
  }
}

// FADE TRANSITION 
@mixin fade-transition($duration) {
	transition-property: all;
    transition-duration: $duration;
    transition-timing-function: ease-out;
}

// BACKGROUND IMAGES 
@mixin object-fit($background-size) {
	-o-object-fit: $background-size;
	object-fit: $background-size;
}